import React, { useState } from "react"
import Footer from "../components/footer"
import Header from "../components/header"
// import "../../styles/solution/game.less"
import SEO from "../components/SEO"
import { Button } from "../components/ui"
// import Button from "../components/Button"
import { ZgaLaunchPopButton, ZgaLaunchPageForm } from "../components/form-biz"
// import { Tab } from "../../components/ui"
// import Drop from "../../components/drop"
import "../styles/event-lp.less"
// import { SolutionTopBanner } from "../components/block"
const loadText = require("src/utils").loadText
// const url_data = loadText("url")
const navigate = url => window.open(url)
const data = loadText("zga-launch")
const TopBanner = () => {
  const { topBanner } = data
  return (
    <div className="topBanner">
      <div className="topBanner-bg">
        {/* <Header logo="/logo-white.svg" theme="dark" /> */}
        <div className="headerContainer only-desktop headerContainer-desk">
          <Header logo="/logo-white.svg" theme="dark" />
        </div>
        <div className="headerContainer ">
          <span className="only-mobile">
            <Header logo="/logo-white.svg" theme="dark" />
          </span>
        </div>

        <div className="topBanner-content">
          <div className="title">{topBanner.title}</div>
          <div className="subtitle">{topBanner.subtitle}</div>
          <div className="description">{topBanner.description}</div>
          <div className="btn-row">
            <ZgaLaunchPopButton buttonWidth={304} source="ZGA Launch">
              {topBanner.buttonText}
            </ZgaLaunchPopButton>
          </div>
        </div>
      </div>
    </div>
  )
}
const Speaker = () => (
  <div className="speaker">
    {data.speakers.map(({ name, title }, idx) => (
      <div className="speaker-item">
        <img src={`/zga-launch/avatar-${idx + 1}.png`} alt={name} />
        <div
          className="speaker-name"
          style={{
            backgroundImage: `url("/zga-launch/avatar-icon-${idx + 1}.svg")`,
          }}
        >
          <div className="name">{name}</div>
          <div className="title">{title}</div>
        </div>
      </div>
    ))}
  </div>
)
const Content = () => (
  <div
    className="content"
    dangerouslySetInnerHTML={{ __html: data.content.join("") }}
  >
    {/* {data.content.map(c => < html={{dangerouslysetinnerhtml}}></>)} */}
  </div>
)
export default function Home() {
  return (
    <div className="event-lp">
      <SEO {...data.seo} featuredImage="/featuredImage/gia.jpg" />
      <TopBanner />
      <Content />
      <Speaker />
      <ZgaLaunchPageForm title="Register for the event" description="" />
      <Footer />
    </div>
  )
}
